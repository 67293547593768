import { VariantProps } from 'class-variance-authority';
import { MouseEvent, forwardRef, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PdfIcon from '@shadcn/custom/PdfIcon';
import {
  Card,
  Button,
  CardContent,
  CardFooter,
  ScrollArea,
  Dialog,
  DialogTrigger,
  Badge,
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  CardHeader,
  toastVariants,
  CardTitle,
} from '@shadcn/ui';
import documentServices from 'app/services/document-services';
import { extractError } from 'app/utils/appHelpers';
import {
  LoaderCircleIcon,
  PlusIcon,
  FileTextIcon,
  FileImageIcon,
  FileSpreadsheetIcon,
  FileIcon,
  EllipsisVerticalIcon,
} from 'lucide-react';

import { DocumentStepper } from './DocumentStepper';

export interface Document {
  _id: string;
  attributes: {
    fileName: string;
    documentType: string;
    status: string;
    createdAt: string;
    fileSize: number;
    detectedData?: Record<string, any>;
  };
}

const getFileIcon = (fileType: string) => {
  switch (fileType.toLowerCase()) {
    case 'pdf':
      return <PdfIcon className="stroke-chart-5" />;
    case 'image':
      return <FileImageIcon className="stroke-chart-2" />;
    case 'spreadsheet':
      return <FileSpreadsheetIcon className="stroke-chart-1" />;
    case 'document':
      return <FileTextIcon className="stroke-chart-4" />;
    default:
      return <FileIcon className="stroke-chart-3" />;
  }
};

const FileCard = ({
  file,
  onDelete,
  isProjectFile,
}: {
  file: Document;
  onDelete: (id: string) => Promise<{ success: boolean }>;
  isProjectFile?: boolean;
}) => {
  const navigate = useNavigate();

  const handleDelete = async (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    await onDelete(file._id);
  };

  const handleNavigateToDocument = () => {
    navigate(`/dashboard/document/${file._id}`);
  };

  return (
    <Card
      className="min-h-48 cursor-pointer rounded-xl border border-border shadow-xl transition-all duration-300 hover:border-primary-foreground hover:shadow-lg"
      onClick={handleNavigateToDocument}
    >
      <CardHeader className="flex flex-row items-center justify-between p-4 pt-2">
        <CardTitle className="flex flex-row items-center justify-start gap-4 text-base font-medium">
          {getFileIcon(file.attributes.documentType || 'pdf')}
          {file.attributes.status}
        </CardTitle>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button size="icon" variant="ghost">
              <EllipsisVerticalIcon />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem onClick={handleNavigateToDocument}>View Details</DropdownMenuItem>
            {isProjectFile ? (
              <DropdownMenuItem variant="destructive" onClick={handleDelete}>
                Remove from Project
              </DropdownMenuItem>
            ) : null}
          </DropdownMenuContent>
        </DropdownMenu>
      </CardHeader>
      <CardContent className="px-4 py-0">
        <h6 className="overflow-hidden overflow-ellipsis whitespace-nowrap" title={file.attributes.fileName}>
          {file.attributes.fileName}
        </h6>
        <p className="mb-2 text-muted-foreground">{new Date(file.attributes.createdAt).toLocaleDateString()}</p>
        <div className="mt-auto flex flex-row flex-wrap gap-2">
          {file.attributes.detectedData
            ? Object.keys(file.attributes.detectedData).map((key) => (
                <Badge key={key} variant="outline">
                  {key}
                </Badge>
              ))
            : null}
        </div>
      </CardContent>
      <CardFooter className="p-4">
        {isProjectFile ? (
          <Badge variant="secondary" className="ml-auto">
            Size: {(file.attributes.fileSize / 1024 / 1024).toFixed(2)} MB
          </Badge>
        ) : null}
      </CardFooter>
    </Card>
  );
};

interface DocumentPageProps {
  projectId: string;
  onSnackbar: (message: string, variant: VariantProps<typeof toastVariants>['variant']) => void;
}

export const Documents = forwardRef<HTMLDivElement, DocumentPageProps>(({ projectId, onSnackbar }, ref) => {
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  const fetchDocuments = useCallback(async () => {
    try {
      return await documentServices.getByProjectId(projectId);
    } catch (error) {
      console.log({ error });
      onSnackbar('Failed to fetch documents', 'destructive');
    }
  }, [projectId, onSnackbar]);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    fetchDocuments()
      .then((docs) => {
        if (isMounted && docs) {
          setDocuments(docs);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });

    return () => {
      isMounted = false;
    };
  }, [fetchDocuments, refreshTrigger]);

  const handleDocumentDelete = async (fileId: string) => {
    setIsLoading(true);

    const msg = { success: 'Documents deleted successfully.', fail: 'Failed to delete document.' };
    try {
      const result = await documentServices.delete(fileId);
      if (result && result.status === 204) {
        onSnackbar(msg.success, 'destructive');
        setRefreshTrigger((prev) => prev + 1);
        return { success: true };
      } else {
        throw new Error(msg.fail);
      }
    } catch (error) {
      onSnackbar(extractError(error) || msg.fail, 'destructive');
      return { success: false };
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateDocument = async (files) => {
    setIsLoading(true);

    try {
      const results = await documentServices.create({ files: files, projectId });
      onSnackbar('Documents created successfully.', 'positive');
      setRefreshTrigger((prev) => prev + 1);
      setIsUploadDialogOpen(false);
      return results;
    } catch (error) {
      onSnackbar(extractError(error) || 'Failed to create documents.', 'destructive');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex h-full grow items-center justify-center">
        <LoaderCircleIcon className="animate-spin" />
      </div>
    );
  }

  return (
    <div ref={ref} className="flex grow animate-fade-up-in flex-col overflow-hidden px-4 pt-6">
      <div className="flex w-full flex-row items-center justify-between gap-2 px-4 pb-2">
        <h2 className="font-bold">Project Documents</h2>
        <Dialog open={isUploadDialogOpen} onOpenChange={(v) => setIsUploadDialogOpen(v)}>
          <DialogTrigger asChild>
            <Button variant={documents.length ? 'default' : 'secondary'} onClick={() => setIsUploadDialogOpen(true)}>
              <PlusIcon /> Add Document
            </Button>
          </DialogTrigger>
          <DocumentStepper onDocumentCreate={handleCreateDocument} />
        </Dialog>
      </div>

      <ScrollArea orientation="both" className="p-4">
        {documents.length ? <h5 className="mb-2">Project Files</h5> : null}
        <div className="grid grid-cols-1 gap-6 pt-1 md:grid-cols-3 lg:grid-cols-4">
          {documents.length ? (
            documents.map((file: Document) => (
              <div key={file._id}>
                <FileCard file={file} onDelete={handleDocumentDelete} isProjectFile />
              </div>
            ))
          ) : (
            <Button
              className="col-span-full justify-self-center"
              variant="default"
              onClick={() => setIsUploadDialogOpen(true)}
            >
              <PlusIcon /> Add Document
            </Button>
          )}
        </div>
      </ScrollArea>
    </div>
  );
});
