import { Dispatch, forwardRef, SetStateAction } from 'react';
import {
  Checkbox,
  ScrollArea,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@shadcn/ui';
import { FileWithMetadata } from './DocumentUpload';

interface EnterDocumentInfoProps {
  files: FileWithMetadata[];
  utilityProvider: string;
  setUtilityProvider: Dispatch<SetStateAction<string>>;
  handleMultipleBillsChange: (index: number) => void;
}

export const EnterDocumentInfo = forwardRef<HTMLDivElement, EnterDocumentInfoProps>(
  ({ utilityProvider, setUtilityProvider, files, handleMultipleBillsChange }, ref) => {
    const handleUtilityProviderChange = (newVal: string) => {
      setUtilityProvider(newVal);
    };

    return (
      <ScrollArea ref={ref} orientation="vertical" className="flex flex-col gap-4 p-2">
        <Select value={utilityProvider} onValueChange={handleUtilityProviderChange}>
          {/* Adjust width/margins for focus outline */}
          <SelectTrigger className="mx-[4px] mt-[4px] w-[calc(100%-8px)]">
            <SelectValue placeholder="Utility Provider" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="con_edison">Con Edison</SelectItem>
            <SelectItem value="psg">Public Service Electric and Gas (PSG)</SelectItem>
            <SelectItem value="national_grid">National Grid</SelectItem>
          </SelectContent>
        </Select>
        {files.length > 0 ? (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>File Name</TableHead>
                <TableHead className="text-right">Multiple Bills?</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {files.map((file, index) => (
                <TableRow key={index}>
                  <TableCell scope="row">{file.fileName}</TableCell>
                  <TableCell className="p-0 text-right">
                    <Checkbox
                      className="mr-4"
                      checked={file.hasMultipleBills}
                      onCheckedChange={() => handleMultipleBillsChange(index)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : null}
      </ScrollArea>
    );
  }
);
