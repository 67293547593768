import React, { useState, useEffect, useRef, useCallback } from 'react';
import { ChevronLeftIcon, ChevronRightIcon, LoaderCircleIcon, MinusIcon, PlusIcon, TrashIcon } from 'lucide-react';
import { PDFDocumentProxy, RenderingCancelledException, RenderTask } from 'pdfjs-dist';
import * as pdfjsLib from 'pdfjs-dist';
import { Button, ScrollArea } from '@shadcn/ui';

interface PDFViewerProps {
  file?: {
    file: File;
  };
  onMarkBillBreak: (page: number) => void;
  onRemoveBillBreak: (page: number) => void;
  billBreaks: number[];
}

export const PDFViewer = ({ file, onMarkBillBreak, onRemoveBillBreak, billBreaks }: PDFViewerProps) => {
  const [pdfDoc, setPdfDoc] = useState<PDFDocumentProxy | null>(null);
  const [pageNum, setPageNum] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const renderTaskRef = useRef<RenderTask>();
  const scale = 0.7;

  const loadPDF = useCallback(async () => {
    if (!file) {
      return;
    }
    try {
      setLoading(true);
      const arrayBuffer = await file.file.arrayBuffer();
      const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
      setPdfDoc(pdf);
      setPageCount(pdf.numPages);
      console.log('PDF loaded, total pages:', pdf.numPages);
    } catch (error) {
      console.error('Error loading PDF:', error);
    } finally {
      setLoading(false);
    }
  }, [file]);

  const renderPage = useCallback(
    async (num) => {
      if (pdfDoc && canvasRef.current) {
        try {
          setLoading(true);
          await renderTaskRef.current?.cancel();

          const page = await pdfDoc.getPage(num);
          const viewport = page.getViewport({ scale });
          const canvas = canvasRef.current;
          const context = canvas.getContext('2d');

          if (!context) {
            throw new Error("Can't get 2d context");
          }

          context.clearRect(0, 0, canvas.width, canvas.height);

          canvas.height = viewport.height;
          canvas.width = viewport.width;

          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };

          renderTaskRef.current = page.render(renderContext);
          await renderTaskRef.current.promise;

          if (billBreaks.includes(num)) {
            context.beginPath();
            context.moveTo(0, 0);
            context.lineTo(canvas.width, 0);
            context.strokeStyle = 'red';
            context.lineWidth = 5;
            context.stroke();
          }
        } catch (error) {
          if (error instanceof RenderingCancelledException) {
            console.error('Error rendering page:', error);
          }
        } finally {
          setLoading(false);
        }
      }
    },
    [pdfDoc, billBreaks, scale]
  );

  useEffect(() => {
    loadPDF();
  }, [loadPDF]);

  useEffect(() => {
    if (pdfDoc) {
      console.log('Rendering page:', pageNum);
      renderPage(pageNum);
    }
    return () => {
      if (renderTaskRef.current) {
        renderTaskRef.current.cancel();
      }
    };
  }, [pageNum, renderPage, pdfDoc]);

  const changePage = (offset) => {
    console.log('Changing page, current:', pageNum, 'offset:', offset);
    setPageNum((prevPageNum) => {
      const newPageNum = prevPageNum + offset;
      console.log('New page number:', newPageNum);
      return newPageNum > 0 && newPageNum <= pageCount ? newPageNum : prevPageNum;
    });
  };

  if (!file) {
    return <p>No file selected</p>;
  }

  return (
    <div className="flex flex-row overflow-hidden">
      <div className="flex grow flex-col overflow-hidden">
        <div className="mt-4 flex items-center justify-center gap-2 p-2">
          <Button size="icon" onClick={() => changePage(-1)} disabled={pageNum <= 1 || loading}>
            <ChevronLeftIcon />
          </Button>
          <p className="mx-4">
            Page {pageNum} of {pageCount}
          </p>
          <Button size="icon" onClick={() => changePage(1)} disabled={pageNum >= pageCount || loading}>
            <ChevronRightIcon />
          </Button>
          {billBreaks.includes(pageNum) ? (
            <Button onClick={() => onRemoveBillBreak(pageNum)} variant="secondary" disabled={loading} loading={loading}>
              <MinusIcon /> Remove Break
            </Button>
          ) : (
            <Button variant="default" onClick={() => onMarkBillBreak(pageNum)} disabled={loading} loading={loading}>
              <PlusIcon /> Add Break
            </Button>
          )}
        </div>
        <ScrollArea orientation="both" className="grow">
          <canvas ref={canvasRef} style={{ display: loading ? 'none' : 'block' }} />
          {loading ? (
            <div className="flex h-full items-center justify-center">
              <LoaderCircleIcon className="animate-spin" />
            </div>
          ) : null}
        </ScrollArea>
      </div>
      <div className="flex w-[200px] grow flex-col overflow-hidden border-l border-l-border pl-4">
        <h6 className="border-b border-b-border pb-1">Bill Breaks</h6>
        <ScrollArea orientation="vertical" className="grow">
          <ul className="flex flex-col gap-2">
            {billBreaks.map((breakPage) => (
              <li className="flex flex-row items-center justify-between text-sm" key={breakPage}>
                Page {breakPage}
                <Button
                  variant="ghost"
                  className="[&>svg]:h-4"
                  size="icon"
                  onClick={() => onRemoveBillBreak(breakPage)}
                >
                  <TrashIcon />
                </Button>
              </li>
            ))}
          </ul>
        </ScrollArea>
      </div>
    </div>
  );
};
