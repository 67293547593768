import { FileWithMetadata } from 'app/pages/project/tabs/document-steps/DocumentUpload';
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@shadcn/ui';
import { PDFViewer } from 'app/pages/project/tabs/PDFViewer';

interface MarkBillBreaksProps {
  files: { file: File; fileName: string; hasMultipleBills: boolean }[];
  currentFileIndex: number;
  setCurrentFileIndex: Dispatch<SetStateAction<number>>;
  billBreaks: Record<string, number[]>;
  setBillBreaks: Dispatch<SetStateAction<Record<string, number[]>>>;
  filesWithMultipleBills: FileWithMetadata[];
}

export const MarkBillBreaks = ({
  files,
  setBillBreaks,
  setCurrentFileIndex,
  billBreaks,
  currentFileIndex,
  filesWithMultipleBills,
}: MarkBillBreaksProps) => {
  const handleFileChange = (selectedMultiBillIndex: string) => {
    const selectedFile = filesWithMultipleBills[+selectedMultiBillIndex];
    const newIndex = files.findIndex((file) => file.fileName === selectedFile.fileName);
    setCurrentFileIndex(newIndex);
  };

  const currentMultiBillFileIndex = useMemo(() => {
    const index = filesWithMultipleBills.findIndex((file) => file.fileName === files[currentFileIndex]?.fileName);
    return index >= 0 ? index : 0;
  }, [filesWithMultipleBills, files, currentFileIndex]);

  const handleMarkBillBreak = useCallback(
    (pageNum: number) => {
      setBillBreaks((prev) => {
        const currentBreaks = prev[files[currentFileIndex].fileName] || [];
        return {
          ...prev,
          [files[currentFileIndex].fileName]: [...new Set<number>([...currentBreaks, pageNum])].sort((a, b) => a - b),
        };
      });
    },
    [currentFileIndex, files]
  );

  const handleRemoveBillBreak = useCallback(
    (pageNum) => {
      setBillBreaks((prev) => {
        const currentBreaks = prev[files[currentFileIndex].fileName] || [];
        return {
          ...prev,
          [files[currentFileIndex].fileName]: currentBreaks.filter((num) => num !== pageNum),
        };
      });
    },
    [currentFileIndex, files]
  );

  return (
    <div className="flex flex-col gap-4 overflow-hidden">
      {filesWithMultipleBills.length > 0 ? (
        <>
          <Select value={`${currentMultiBillFileIndex}`} onValueChange={handleFileChange}>
            <SelectTrigger>
              <SelectValue placeholder="Select File" />
            </SelectTrigger>
            <SelectContent>
              {filesWithMultipleBills.map((file, index) => (
                <SelectItem key={file.fileName || index} value={`${index}`}>
                  {file.fileName}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          {files[currentFileIndex] && files[currentFileIndex].hasMultipleBills && (
            <PDFViewer
              key={files[currentFileIndex].fileName}
              file={files[currentFileIndex]}
              onMarkBillBreak={handleMarkBillBreak}
              onRemoveBillBreak={handleRemoveBillBreak}
              billBreaks={billBreaks[files[currentFileIndex].fileName] || []}
            />
          )}
        </>
      ) : (
        <p>No files with multiple bills. You can proceed to the next step.</p>
      )}
    </div>
  );
};
