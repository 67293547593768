import { MarkBillBreaks } from 'app/pages/project/tabs/document-steps/MarkBillBreaks';
import { EnterDocumentInfo } from 'app/pages/project/tabs/document-steps/EnterDocumentInfo';
import { DocumentUpload, FileWithMetadata } from 'app/pages/project/tabs/document-steps/DocumentUpload';
import { ReviewUpload } from 'app/pages/project/tabs/document-steps/ReviewUpload';
import { useState, useMemo } from 'react';
import axios from 'axios';
import {
  Alert,
  Button,
  DialogBody,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@shadcn/ui';
import { Stepper, StepperList, StepperContent, StepLabel } from '@shadcn/custom/Stepper';
import { TriangleAlertIcon } from 'lucide-react';
import * as pdfjsLib from 'pdfjs-dist';
import '../../../../../node_modules/pdfjs-dist/build/pdf.worker.min.mjs';

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const steps = ['Upload PDFs', 'Enter Document Info', 'Mark Bill Breaks', 'Review & Process'];

export const DocumentStepper = ({ onDocumentCreate }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [files, setFiles] = useState<FileWithMetadata[]>([]);
  const [utilityProvider, setUtilityProvider] = useState('');
  const [uploadProgress, setUploadProgress] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [billBreaks, setBillBreaks] = useState({});
  const [error, setError] = useState<null | string>(null);

  const filesWithMultipleBills = useMemo(() => files.filter((file) => file.hasMultipleBills), [files]);

  const handleNext = () => {
    if (activeStep === 1) {
      if (filesWithMultipleBills.length > 0) {
        // Go to Mark Bill Breaks
        setActiveStep(2);
        setCurrentFileIndex(files.findIndex((file) => file.fileName === filesWithMultipleBills[0].fileName));
      } else {
        // Skip to Review & Process
        setActiveStep(3);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 3 && filesWithMultipleBills.length === 0) {
      // Go back to Enter Document Info
      setActiveStep(1);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleMultipleBillsChange = (index: number) => {
    setFiles((prevFiles) =>
      prevFiles.map((file, i) => (i === index ? { ...file, hasMultipleBills: !file.hasMultipleBills } : file))
    );
    if (!files[index].hasMultipleBills) {
      setBillBreaks((prev) => {
        const newBreaks = { ...prev };
        delete newBreaks[files[index].fileName];
        return newBreaks;
      });
    } else if (filesWithMultipleBills.length === 0) {
      setCurrentFileIndex(index);
    }
  };

  const handleRemoveFile = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setBillBreaks((prev) => {
      const newBreaks = { ...prev };
      delete newBreaks[files[index].fileName];
      return newBreaks;
    });
  };

  const handleUpload = async () => {
    if (files.length === 0) {
      setError('No documents to upload. You must select a document to submit.');
      return;
    }

    if (!utilityProvider) {
      setError('You must select a utility provider.');
      return;
    }

    setIsUploading(true);
    setError(null);

    try {
      const results = await onDocumentCreate(files);
      const uploadPromises = results.map(async (doc) => {
        const {
          attributes: { uploadUrl, fileName },
        } = doc;
        const file = files.find((f) => f.fileName === fileName);
        if (!file) {
          return;
        }
        const requestOptions = {
          headers: { 'Content-Type': file.mimeType },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress((prev) => ({ ...prev, [fileName]: percentCompleted }));
          },
        };
        return axios.put(uploadUrl, file.file, requestOptions);
      });

      await Promise.all(uploadPromises);
    } catch (error) {
      setError(`Upload failed: ${error instanceof Error ? error.message : 'Unknown error.'}`);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <DialogContent className="h-full max-h-[1000px] w-full sm:h-[60vh] sm:min-w-fit">
      <DialogHeader>
        <DialogTitle>Upload Documents</DialogTitle>
      </DialogHeader>
      <DialogBody className="grow overflow-hidden">
        <Stepper
          className="flex flex-col overflow-hidden"
          value={`${activeStep}`}
          onValueChange={(v) => setActiveStep(parseInt(v, 10))}
        >
          <StepperList className="mb-6">
            {steps.map((label, index) => {
              return (
                <StepLabel value={`${index}`} key={label + index}>
                  {label}
                </StepLabel>
              );
            })}
          </StepperList>
          <StepperContent asChild value="0">
            <DocumentUpload files={files} setFiles={setFiles} handleRemoveFile={handleRemoveFile} setError={setError} />
          </StepperContent>
          <StepperContent asChild value="1">
            <EnterDocumentInfo
              files={files}
              utilityProvider={utilityProvider}
              setUtilityProvider={setUtilityProvider}
              handleMultipleBillsChange={handleMultipleBillsChange}
            />
          </StepperContent>
          <StepperContent asChild value="2">
            <MarkBillBreaks
              currentFileIndex={currentFileIndex}
              files={files}
              billBreaks={billBreaks}
              setBillBreaks={setBillBreaks}
              filesWithMultipleBills={filesWithMultipleBills}
              setCurrentFileIndex={setCurrentFileIndex}
            />
          </StepperContent>
          <StepperContent asChild value="3">
            <ReviewUpload
              uploadProgress={uploadProgress}
              handleUpload={handleUpload}
              isUploading={isUploading}
              utilityProvider={utilityProvider}
              files={files}
              billBreaks={billBreaks}
            />
          </StepperContent>
        </Stepper>
        {error ? (
          <Alert className="mt-4" variant="destructive">
            <TriangleAlertIcon /> <span>{error}</span>
          </Alert>
        ) : null}
      </DialogBody>
      <DialogFooter className="">
        <Button variant="secondary" disabled={activeStep === 0} onClick={handleBack}>
          Back
        </Button>
        {activeStep < steps.length - 1 ? (
          <Button onClick={handleNext} disabled={files.length === 0 || (activeStep === 1 && !utilityProvider)}>
            Next
          </Button>
        ) : (
          <DialogClose asChild>
            <Button variant="secondary">Cancel</Button>
          </DialogClose>
        )}
      </DialogFooter>
    </DialogContent>
  );
};
