import { cn } from '@shadcn/utils';
import { NewReportModal } from 'app/pages/project/tabs/NewReportModal';
import { VariantProps } from 'class-variance-authority';
import { DownloadIcon, EllipsisVerticalIcon, LoaderCircleIcon, PlusIcon, TrashIcon } from 'lucide-react';
import { forwardRef, useCallback, useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  CardFooter,
  Badge,
  Button,
  Dialog,
  DialogTrigger,
  ScrollArea,
  CardHeader,
  CardTitle,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  toastVariants,
} from '@shadcn/ui';
import { format } from 'date-fns';

import reportServices from 'app/services/report-services';
import { extractError } from 'app/utils/appHelpers';

type Report = {
  id: string;
  attributes: {
    name: string;
    status: string;
    createdAt: string;
  };
};

const getBadgeVariant = (status: string) => {
  switch (status.toLowerCase()) {
    case 'completed':
      return 'positive';
    case 'in progress':
      return 'warning';
    case 'failed':
      return 'destructive';
    default:
      return 'default';
  }
};

interface ReportsProps {
  projectId: string;
  onSnackbar: (message: string, variant: VariantProps<typeof toastVariants>['variant']) => void;
}

export const Reports = forwardRef<HTMLDivElement, ReportsProps>(({ projectId, onSnackbar }, ref) => {
  const [reports, setReports] = useState<Report[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openNewReportDialog, setOpenNewReportDialog] = useState(false);
  const [selectedReport, setSelectedReport] = useState<Report | null>(null);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  const fetchReports = useCallback(async () => {
    try {
      return await reportServices.getByProjectId(projectId);
    } catch (error) {
      onSnackbar('An unexpected error occurred while fetching the reports', 'destructive');
    }
  }, [projectId, onSnackbar]);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    fetchReports().then((fetchedReports) => {
      if (isMounted) {
        setReports(fetchedReports);
        setIsLoading(false);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [fetchReports, refreshTrigger]);

  const handleOpenMenu = (report: Report) => {
    setSelectedReport(report);
  };

  const handleCloseMenu = () => {
    setSelectedReport(null);
  };

  const downloadFile = (url, fileName) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      })
      .catch((error) => {
        onSnackbar(error.message || 'Failed to download report', 'destructive');
      });
  };

  const handleDownload = async (report: Report) => {
    const { id: reportId } = report;
    try {
      const downloadUrl = await reportServices.getDownloadLink(reportId);
      downloadFile(downloadUrl, reportId);
      handleCloseMenu();
    } catch (e) {
      onSnackbar('Failed to downlaod report.', 'destructive');
    }
  };

  const handleDeleteReport = async () => {
    if (!selectedReport) {
      return;
    }
    setIsDeleting(true);

    try {
      const { status } = await reportServices.delete(selectedReport.id);
      if (status === 204) {
        onSnackbar('Report deleted successfully', 'positive');
        setRefreshTrigger((prev) => prev + 1);
        handleCloseMenu();
      } else {
        throw new Error('Failed to delete the report');
      }
    } catch (error) {
      onSnackbar(extractError(error) || 'Failed to delete the report', 'destructive');
    } finally {
      setIsDeleting(false);
    }
  };

  const handleOpenNewReportDialog = () => {
    setOpenNewReportDialog(true);
  };

  const handleCloseNewReportDialog = () => {
    setOpenNewReportDialog(false);
  };

  if (isLoading) {
    return (
      <div className="flex h-full grow items-center justify-center">
        <LoaderCircleIcon className="animate-spin" />
      </div>
    );
  }

  return (
    <div ref={ref} className="flex grow animate-fade-up-in flex-col overflow-hidden px-4 pt-6">
      <div className="flex w-full flex-row items-center justify-between gap-2 px-4 pb-2">
        <h2 className="font-bold">Reports</h2>

        <Dialog
          open={openNewReportDialog}
          onOpenChange={(v) => (v ? handleOpenNewReportDialog() : handleCloseNewReportDialog())}
        >
          {reports.length ? (
            <DialogTrigger asChild>
              <Button onClick={handleOpenNewReportDialog} disabled={isDeleting}>
                <PlusIcon /> New Report
              </Button>
            </DialogTrigger>
          ) : null}
          <NewReportModal
            projectId={projectId}
            setRefreshTrigger={setRefreshTrigger}
            handleClose={handleCloseNewReportDialog}
          />
        </Dialog>
      </div>

      {reports.length ? (
        <ScrollArea
          orientation="vertical"
          className={cn('grow p-4', {
            '': !reports.length,
          })}
        >
          <div className="grid grid-cols-1 gap-6 pt-1 md:grid-cols-3 lg:grid-cols-4">
            {reports.map((report) => (
              <Card className="min-h-48 rounded-xl border border-border shadow-xl" key={report.id}>
                <CardHeader className="flex w-full flex-row items-center justify-between">
                  <CardTitle>{report.attributes.name}</CardTitle>

                  <DropdownMenu
                    open={selectedReport?.id === report.id}
                    onOpenChange={(v) => (v ? handleOpenMenu(report) : handleCloseMenu())}
                  >
                    <DropdownMenuTrigger asChild>
                      <Button size="icon" variant="ghost" disabled={isDeleting}>
                        <EllipsisVerticalIcon />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      <DropdownMenuItem
                        className="flex flex-row gap-2"
                        variant="destructive"
                        onClick={handleDeleteReport}
                      >
                        <TrashIcon className="h-6 w-6" />
                        Delete
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </CardHeader>
                <CardContent>
                  <p className="mb-4 text-muted-foreground">
                    Created: {format(new Date(report.attributes.createdAt), 'MMM d, yyyy HH:mm')}
                  </p>
                  <div className="mt-2 flex items-start justify-between">
                    <Badge variant={getBadgeVariant(report.attributes.status)}>
                      {report.attributes.status.toUpperCase()}
                    </Badge>
                  </div>
                </CardContent>
                <CardFooter>
                  {report.attributes.status === 'completed' ? (
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => handleDownload(report)}
                          disabled={isDeleting}
                        >
                          <DownloadIcon />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>Download Report</TooltipContent>
                    </Tooltip>
                  ) : null}
                </CardFooter>
              </Card>
            ))}
          </div>
        </ScrollArea>
      ) : (
        <Button className="self-center" variant="default" onClick={handleOpenNewReportDialog} disabled={isDeleting}>
          <PlusIcon /> New Report
        </Button>
      )}
    </div>
  );
});
