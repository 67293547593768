import { useState, useEffect, forwardRef, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { InputWrapper } from '@shadcn/custom/InputWrapper';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  ScrollArea,
  toastVariants,
  Slider,
} from '@shadcn/ui';
import { cn } from '@shadcn/utils';
import { VariantProps } from 'class-variance-authority';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsToolTip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Bar,
} from 'recharts';
import {
  SunIcon,
  CloudRainIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  RefreshCwIcon,
  Loader2Icon,
  XIcon,
} from 'lucide-react';
import projectServices, { WeatherParams } from 'app/services/project-services';
import { extractError } from 'app/utils/appHelpers';
import { ELECTRIC, GAS, STEAM, OIL } from 'app/utils/constants/utilityServices';

const SERVICE_METRICS = {
  electric: 'total_usage_kwh',
  steam: 'total_steam_use_mlb',
  gas: 'total_gas_use_therms',
  oil: 'units',
};

const MAX_RETRIES = 3;
const RETRY_DELAY = 2000;

const fetchWithRetry = async (
  fetchFn: () => Promise<any>,
  attempt = 0,
  maxRetries = MAX_RETRIES,
  delay = RETRY_DELAY
) => {
  try {
    return await fetchFn();
  } catch (error) {
    if (attempt >= maxRetries) {
      throw error;
    }
    await new Promise((resolve) => setTimeout(resolve, delay * Math.pow(2, attempt)));
    return fetchWithRetry(fetchFn, attempt + 1, maxRetries, delay);
  }
};

interface DaysProps {
  temp: number;
  tempmax: number;
}

export interface WeatherProps {
  address: string;
  days: DaysProps[];
}

interface VisualizationsProps {
  projectId: string;
  onSnackbar: (message: string, variant: VariantProps<typeof toastVariants>['variant']) => void;
}

interface InsightCardProps {
  title: string;
  value: string | number;
  unit?: string;
}

interface DocumentData {
  months: Array<{
    billing_end_date: string;
    billing_start_date: string;
    billing_summary_date: string;
  }>;
  startDate: string;
  endDate: string;
  stats: {
    loadFactor: number;
    avgLoad: number;
    totalUsage: number;
    totalCost: number;
  };
}

interface Documents {
  [key: string]: DocumentData;
}

interface MainChartProps {
  data: any[];
  metric: string;
  weatherData?: WeatherProps;
  units: string;
}

const InsightCard = ({ title, value, unit }: InsightCardProps) => (
  <Card className="rounded-xl border-border bg-white/10 shadow-lg transition duration-300 hover:border-border/5 hover:bg-white/5">
    <CardHeader>
      <CardTitle className="">{title}</CardTitle>
    </CardHeader>
    <CardContent>
      {value + ' '} {unit ? <span className="text-muted-foreground">{unit}</span> : null}
    </CardContent>
  </Card>
);

const formatNumber = (num, decimalPlaces = 0) => {
  if (num === null || num === undefined || isNaN(num)) {
    return '0';
  }

  const parts = Number(num).toFixed(decimalPlaces).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

const formatDateForInput = (dateString: string) => {
  if (!dateString) return '';
  const [month, day, year] = dateString.split('-');
  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
};

const MainChart = ({ data, metric, weatherData, units }: MainChartProps) => (
  <ResponsiveContainer width="100%" height={300}>
    <LineChart data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="monthYear" />
      <YAxis
        yAxisId="left"
        orientation="left"
        label={{ value: units, angle: -90, position: 'insideLeft', fill: 'hsl(var(--foreground))' }}
        tickFormatter={(value) => formatNumber(value)}
      />
      {weatherData ? (
        <YAxis
          label={{
            value: 'Degrees (°F)',
            angle: -90,
            position: 'insideRight',
            fill: 'hsl(var(--foreground))',
          }}
          tickFormatter={(value) => formatNumber(value)}
          yAxisId="right"
          orientation="right"
        />
      ) : null}
      <RechartsToolTip
        contentStyle={{ backgroundColor: 'hsl(var(--background))', border: 'none', borderRadius: '4px' }}
        labelStyle={{ color: 'hsl(var(--foreground))' }}
        itemStyle={{ color: 'hsl(var(--foreground))' }}
        formatter={(value) => formatNumber(value)}
      />
      <Legend />
      <Line
        yAxisId="left"
        unit={` ${units}`}
        name={units}
        type="linear"
        dataKey={metric}
        stroke="#8884d8"
        activeDot={{ r: 8 }}
      />
      {weatherData ? (
        <Line unit=" °F" name="Temperature" yAxisId="right" type="linear" dataKey="temperature" stroke="#82ca9d" />
      ) : null}
    </LineChart>
  </ResponsiveContainer>
);

export const Visualizations = forwardRef<HTMLDivElement, VisualizationsProps>(({ projectId, onSnackbar }, ref) => {
  const [documents, setDocuments] = useState<Documents>({});
  const [isLoading, setIsLoading] = useState(true);
  const [visSections, setVisSections] = useState<{ [key: string]: boolean }>({});
  const [isWeatherOpen, setIsWeatherOpen] = useState(false);
  const [zipCode, setZipCode] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isWeatherLoading, setIsWeatherLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [weather, setWeather] = useState({} as WeatherProps);
  const [degreeData, setDegreeData] = useState({});
  const [baseloads, setBaseloads] = useState({});
  const [baseTemperature, setBaseTemperature] = useState(65);
  const [isUpdatingDegreeData, setIsUpdatingDegreeData] = useState(false);

  const fetchData = useCallback(
    async (baseTemp: number) => {
      setIsUpdatingDegreeData(true);

      try {
        const insightsParams = { id: projectId, data: { baseTemp, degreeDataTypes: ['hdd', 'cdd'] } };
        const regressionResult = await fetchWithRetry(async () => projectServices.fetchProjectInsights(insightsParams));

        const { degreeData, baseloads, formattedDocuments, services, weather, zipCode } = regressionResult;

        setDocuments(formattedDocuments);
        setVisSections(services.reduce((acc, key) => ({ ...acc, [key]: true }), {}));
        setWeather(weather);
        setBaseloads(baseloads);
        setDegreeData(degreeData);
        setZipCode(zipCode || '');

        if (formattedDocuments && Object.keys(formattedDocuments).length > 0) {
          const startDate = Object.values(formattedDocuments as DocumentData).reduce(
            (earliest, doc) => {
              const { startDate } = doc;
              return startDate !== '' && startDate < earliest ? startDate : earliest;
            },
            Object.values(formattedDocuments as DocumentData)[0].startDate
          );

          const endDate = Object.values(formattedDocuments as DocumentData).reduce(
            (latest, doc) => {
              const { endDate } = doc;
              return endDate !== '' && endDate > latest ? endDate : latest;
            },
            Object.values(formattedDocuments as DocumentData)[0].endDate
          );

          setStartDate(formatDateForInput(startDate));
          setEndDate(formatDateForInput(endDate));
        }
      } catch (error) {
        onSnackbar(extractError(error) || 'Failed to update degree day data.', 'destructive');
      } finally {
        setIsUpdatingDegreeData(false);
        setIsLoading(false);
      }
    },
    [projectId, onSnackbar]
  );

  useEffect(() => {
    fetchData(baseTemperature);
  }, [baseTemperature]);

  const handleSliderChange = (value: number[]) => {
    const newValue = value[0];
    setBaseTemperature(newValue);
  };

  if (isLoading) {
    return (
      <div className="flex h-[100%] items-center justify-center py-8">
        <Loader2Icon className={cn('h-20 w-20 animate-spin text-primary')} />
      </div>
    );
  }

  const renderLoadMetric = (serviceType: string) => {
    switch (serviceType) {
      case ELECTRIC:
        return 'kWh';
      case GAS:
        return 'therms';
      case STEAM:
        return 'Mlbs';
      case OIL:
        return 'gallons';
      default:
        return 'Unknown metric';
    }
  };

  const formatValue = (value: number | undefined, isPercentage: boolean = false) => {
    if (!value) return '0';

    if (isPercentage) {
      return `${(value * 100).toFixed(2)}%`;
    }
    return value.toLocaleString();
  };

  const getTrendData = (serviceType: string, degreeDayType: string) => {
    if (!documents[serviceType]) return [];

    const hasBaseloads = !!baseloads?.[serviceType];
    const hasWeather = !!weather;
    const hasDegreeData = !!degreeData;

    return documents[serviceType].months.map((month) => {
      const isOil = serviceType === 'oil';
      const [m, d, y] = isOil ? month.billing_summary_date.split('-') : month.billing_start_date.split('-');
      const monthDayYear = `${m}-${d}-${y}`;
      const monthYear = `${m}-${y}`;
      const result: any = {
        monthYear: isOil ? monthDayYear : monthYear,
        totalUsage: month[SERVICE_METRICS[serviceType]],
      };

      if (serviceType === ELECTRIC) {
        result.peakDemandKw = month['total_demand_kw'];
      }

      if (hasWeather && weather[monthYear]) {
        result.temperature = weather[monthYear]['avg'];
      }

      if (hasBaseloads) {
        result.baseload = baseloads[serviceType].baseload;
      }

      if (hasDegreeData && degreeData[monthYear]) {
        result.degreeDay = degreeData[monthYear][degreeDayType];
        result.degreeDayMetric = degreeDayType;
      }

      return result;
    });
  };

  const handleAddWeather = async () => {
    setIsWeatherLoading(true);
    try {
      const params: WeatherParams = {
        id: projectId,
        data: { zipCode, startDate, endDate },
      };

      const savedProject = await projectServices.addWeather(params);
      if (savedProject) {
        onSnackbar('Weather data added successfully.', 'positive');
        setWeather(savedProject.weather);
        setIsEditing(false);
        setIsWeatherOpen(false);
      }
    } catch (error) {
      onSnackbar(extractError(error) || 'Failed to add weather data.', 'destructive');
    } finally {
      setIsWeatherLoading(false);
    }
  };

  const toggleWeather = () => {
    setIsWeatherOpen(!isWeatherOpen);
    setIsEditing(false);
  };

  const startEditing = () => {
    setIsEditing(true);
  };

  const cancelEditing = () => {
    setIsEditing(false);
  };

  const renderWeatherIcon = () => {
    let hasDocuments = false;
    Object.values(documents).forEach((entry) => {
      if (entry?.months.length > 0) {
        hasDocuments = true;
      }
    });

    const buttonClass = hasDocuments ? '' : 'opacity-50';
    const tooltipContent = hasDocuments ? 'Add or edit weather data' : 'Add documents to enable weather data';
    return (
      <Tooltip>
        <TooltipTrigger asChild>
          <span className="inline-block">
            <Button onClick={toggleWeather} disabled={!hasDocuments} className={buttonClass}>
              {isWeatherOpen ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
              {weather && Object.keys(weather).length > 0 ? (
                <SunIcon className="ml-2 h-6 w-6 text-yellow-400" />
              ) : (
                <CloudRainIcon className="ml-2 h-6 w-6" />
              )}
            </Button>
          </span>
        </TooltipTrigger>
        <TooltipContent>{tooltipContent}</TooltipContent>
      </Tooltip>
    );
  };

  return (
    <div ref={ref} className="flex grow animate-fade-up-in flex-col overflow-hidden px-4 pt-6">
      <div className="flex w-full flex-row items-center justify-between gap-2 px-4 pb-2">
        <h2 className="font-bold">Energy Usage Dashboard</h2>
        <div className="flex items-center gap-6">
          <span className="mr-2 text-muted-foreground">Weather Data:</span>
          {renderWeatherIcon()}
        </div>
      </div>

      {isWeatherOpen && Object.keys(documents).length > 0 && (
        <Card className="mx-4 mb-8 animate-fade-up-in rounded-xl border-border">
          <CardHeader>
            <CardTitle>{weather && Object.keys(weather).length > 0 ? 'Weather Data' : 'Add Weather Data'}</CardTitle>
            <CardDescription>
              {weather && Object.keys(weather).length > 0
                ? 'Current weather data for this location.'
                : 'Click to submit a request for weather data in this date range.'}
            </CardDescription>
          </CardHeader>
          <CardContent className="flex flex-col gap-4">
            <InputWrapper
              type="text"
              label="ZIP Code"
              placeholder="ZIP Code"
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
              disabled={!isEditing && weather && Object.keys(weather).length > 0}
            />
            <InputWrapper
              type="date"
              label="Start Date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              disabled={!isEditing && weather && Object.keys(weather).length > 0}
            />
            <InputWrapper
              type="date"
              label="End Date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              disabled={!isEditing && weather && Object.keys(weather).length > 0}
            />
            {weather && Object.keys(weather).length > 0 ? (
              isEditing ? (
                <div className="flex justify-end gap-2">
                  <Button onClick={cancelEditing} variant="outline">
                    <XIcon className="mr-2 h-4 w-4" />
                    Cancel
                  </Button>
                  <Button onClick={handleAddWeather} disabled={isWeatherLoading}>
                    {isWeatherLoading ? <Loader2Icon size="sm" className="mr-2" /> : null}
                    Submit
                  </Button>
                </div>
              ) : (
                <Button onClick={startEditing} className="self-end">
                  <RefreshCwIcon className="mr-2 h-4 w-4" />
                  Edit weather
                </Button>
              )
            ) : (
              <Button onClick={handleAddWeather} disabled={isWeatherLoading} className="self-end">
                {isWeatherLoading ? <Loader2Icon size="sm" className="mr-2" /> : null}
                Submit
              </Button>
            )}
          </CardContent>
        </Card>
      )}

      <ScrollArea orientation="vertical" className="grow p-4">
        <Card className="mx-4 mb-8 animate-fade-up-in rounded-xl border-border">
          <CardHeader>
            <CardTitle>Base Temperature</CardTitle>
            <CardDescription>Adjust the base temperature for degree day calculations</CardDescription>
          </CardHeader>
          <CardContent>
            <div className="flex items-center gap-4">
              <Slider
                min={50}
                max={80}
                step={1}
                value={[baseTemperature]}
                onValueChange={handleSliderChange}
                className="w-64"
              />
              <span>{baseTemperature}°F</span>
              {isUpdatingDegreeData && <Loader2Icon className="h-4 w-4 animate-spin" />}
            </div>
          </CardContent>
        </Card>
        <Accordion type="multiple" defaultValue={Object.keys(visSections)}>
          {Object.keys(visSections).map((serviceType) => (
            <AccordionItem
              value={serviceType}
              key={serviceType}
              className="mb-8 overflow-hidden rounded-lg border border-border px-4"
            >
              <AccordionTrigger className="border-none capitalize" showChevron>
                {serviceType} Usage
              </AccordionTrigger>
              <AccordionContent>
                <div>
                  <div className="mb-8 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4">
                    {serviceType === ELECTRIC ? (
                      <InsightCard
                        title="Load Factor"
                        value={formatValue(documents[serviceType]?.stats?.loadFactor, true)}
                      />
                    ) : null}
                    <InsightCard
                      title="Average Load"
                      value={formatValue(documents[serviceType]?.stats?.avgLoad)}
                      unit={renderLoadMetric(serviceType)}
                    />
                    <InsightCard
                      title="Total Usage"
                      value={formatValue(documents[serviceType]?.stats?.totalUsage)}
                      unit={renderLoadMetric(serviceType)}
                    />
                    <InsightCard
                      title="Total Cost"
                      value={`$${formatValue(documents[serviceType]?.stats?.totalCost, false)}`}
                    />
                    {baseloads[serviceType] ? (
                      <InsightCard
                        title={`${serviceType === ELECTRIC ? 'Cooling' : 'Heating'} baseload`}
                        value={formatValue(baseloads[serviceType].baseload)}
                        unit={renderLoadMetric(serviceType)}
                      />
                    ) : null}
                  </div>
                  <div className="mb-8 rounded-lg bg-white/10 p-6 shadow-lg">
                    <h3 className="mb-6 text-2xl font-semibold text-primary-foreground">Usage Trend</h3>
                    <MainChart
                      data={getTrendData(serviceType, serviceType === ELECTRIC ? 'cdd' : 'hdd')}
                      metric="totalUsage"
                      weatherData={weather}
                      units={renderLoadMetric(serviceType)}
                    />
                  </div>
                  <div className="mb-8 h-[500px] rounded-lg bg-white/10 p-6 shadow-lg">
                    <h3 className="mb-6 text-2xl font-semibold text-primary-foreground">
                      Usage vs. Degree Days (Base Temp: {baseTemperature}°F)
                    </h3>
                    <ResponsiveContainer width="100%" height="100%">
                      <ComposedChart data={getTrendData(serviceType, serviceType === ELECTRIC ? 'cdd' : 'hdd')}>
                        <CartesianGrid strokeDasharray="3 3" stroke="#555" />
                        <XAxis dataKey="monthYear" tick={{ fill: '#E0E0E0' }} />
                        <YAxis
                          yAxisId="left"
                          label={{
                            value: serviceType === ELECTRIC ? 'CDD' : 'HDD',
                            angle: -90,
                            position: 'insideLeft',
                            fill: '#E0E0E0',
                          }}
                          tick={{ fill: '#E0E0E0' }}
                        />
                        <YAxis
                          yAxisId="right"
                          orientation="right"
                          tickFormatter={(value) => formatNumber(value)}
                          label={{
                            value: renderLoadMetric(serviceType),
                            angle: -90,
                            position: 'insideRight',
                            fill: '#E0E0E0',
                          }}
                          tick={{ fill: '#E0E0E0' }}
                        />
                        <RechartsToolTip
                          contentStyle={{ backgroundColor: '#2D2D2D', border: 'none', borderRadius: '4px' }}
                          labelStyle={{ color: '#E0E0E0' }}
                          itemStyle={{ color: '#E0E0E0' }}
                          formatter={(value) => formatNumber(value)}
                        />
                        <Legend wrapperStyle={{ color: '#E0E0E0' }} />
                        <Bar
                          yAxisId="left"
                          dataKey="degreeDay"
                          fill="#7352C7"
                          name={serviceType === ELECTRIC ? 'CDD' : 'HDD'}
                        />
                        <Line
                          yAxisId="right"
                          type="linear"
                          dataKey="totalUsage"
                          stroke="#ff7300"
                          name={renderLoadMetric(serviceType)}
                          dot={{ r: 4 }}
                          activeDot={{ r: 6 }}
                        />
                        <Line
                          yAxisId="right"
                          type="linear"
                          dataKey="baseload"
                          stroke="#E0E0E0"
                          name={`Baseload (${renderLoadMetric(serviceType)})`}
                          dot={{ r: 4 }}
                          activeDot={{ r: 6 }}
                        />
                      </ComposedChart>
                    </ResponsiveContainer>
                  </div>
                  {serviceType === ELECTRIC && (
                    <div className="mb-8 h-[500px] rounded-lg bg-white/10 p-6 shadow-lg">
                      <h3 className="mb-6 text-2xl font-semibold text-primary-foreground">Peak Demand</h3>
                      <ResponsiveContainer width="100%" height="100%">
                        <ComposedChart data={getTrendData(serviceType, serviceType === ELECTRIC ? 'cdd' : 'hdd')}>
                          <CartesianGrid strokeDasharray="3 3" stroke="#555" />
                          <XAxis dataKey="monthYear" tick={{ fill: '#E0E0E0' }} />
                          <YAxis
                            yAxisId="left"
                            label={{
                              value: 'kW',
                              angle: -90,
                              position: 'insideLeft',
                              fill: '#E0E0E0',
                            }}
                            tick={{ fill: '#E0E0E0' }}
                          />
                          {weather ? (
                            <YAxis
                              label={{
                                value: 'Degrees (°F)',
                                angle: -90,
                                position: 'insideRight',
                                fill: 'hsl(var(--foreground))',
                              }}
                              tickFormatter={(value) => formatNumber(value)}
                              yAxisId="right"
                              orientation="right"
                            />
                          ) : null}
                          <RechartsToolTip
                            contentStyle={{ backgroundColor: '#2D2D2D', border: 'none', borderRadius: '4px' }}
                            labelStyle={{ color: '#E0E0E0' }}
                            itemStyle={{ color: '#E0E0E0' }}
                            formatter={(value) => formatNumber(value)}
                          />
                          <Legend wrapperStyle={{ color: '#E0E0E0' }} />
                          <Bar yAxisId="left" dataKey="peakDemandKw" fill="#7352C7" name="Peak demand kW" />
                          {weather ? (
                            <Line
                              unit=" °F"
                              name="Temperature"
                              yAxisId="right"
                              type="linear"
                              dataKey="temperature"
                              stroke="#ff7300"
                            />
                          ) : null}
                        </ComposedChart>
                      </ResponsiveContainer>
                    </div>
                  )}
                </div>
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </ScrollArea>
    </div>
  );
});
