import { useEffect, useState } from 'react';
import { SidebarOpenIcon } from 'lucide-react';
import { Button } from '@shadcn/ui';
import { cn } from '@shadcn/utils';
import { useAppLayout } from 'app/utils/AppLayout';
import { AuthUserDropdown } from 'app/shared/widgets/AuthUserDropdown';
import billingServices from 'app/services/billing-services';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';

export const Header = () => {
  const { sidebarOpen, setSidebarOpen } = useAppLayout();
  const [currentBillPlan, setCurrentBillPlan] = useState({});
  const { authUser } = useJumboAuth();

  useEffect(() => {
    async function fetchBillPlanFromUrl() {
      const billing = await billingServices.getBilling();
      if (!ignore) {
        if (billing.id) {
          setCurrentBillPlan(billing);
        }
      }
    }

    let ignore = false;
    if (authUser) {
      fetchBillPlanFromUrl();
    }

    return () => {
      ignore = true;
    };
  }, []);

  return (
    <header className="sticky m-4 mr-6 flex flex-row items-center justify-between">
      <Button
        className={cn('transition-opacity duration-300 ease-in-out', {
          'pointer-events-none opacity-0': sidebarOpen,
        })}
        size="icon"
        variant="ghost"
        onClick={() => setSidebarOpen((v) => !v)}
      >
        <SidebarOpenIcon />
      </Button>
      <AuthUserDropdown />
    </header>
  );
};
