import { SoloPage } from 'app/layouts/solo-page/SoloPage';
import { VerticalDefault } from 'app/layouts/vertical-default/VerticalDefault';
import { RouteObject } from 'react-router-dom';
import { Page } from '@jumbo/shared/Page';
import { Login } from 'app/pages/auth-pages/login';
import { ResetPassword } from 'app/pages/auth-pages/reset-password';
import { SetPassword } from 'app/pages/auth-pages/set-password';
import { Profile } from 'app/pages/profile/Profile';
import { Privacy } from 'app/pages/privacy/Privacy';
import { TermsOfService } from 'app/pages/terms-of-service/TermsOfService';
import { PricingPlan } from 'app/pages/billing';
import { Landing } from 'app/pages/auth-pages/landing';
import { Project } from 'app/pages/project/Project';
import { ProjectDetail } from 'app/pages/project/ProjectDetail';
import { DocumentDetail } from 'app/pages/documents/DocumentDetail';

/**
 routes accessible to both authenticated and anonymous users
 **/
const routesForPublic: RouteObject[] = [
  {
    path: '/',
    element: <Page Component={Landing} Layout={SoloPage} componentProps={{ disableSmLogin: true }} />,
  },
  {
    path: '/privacy',
    element: <Page Component={Privacy} Layout={SoloPage} />,
  },
  {
    path: '/terms-of-service',
    element: <Page Component={TermsOfService} Layout={SoloPage} />,
  },
];

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly: RouteObject[] = [
  {
    path: '/dashboard/project',
    element: <Page Layout={VerticalDefault} Component={Project} />,
  },
  {
    path: '/dashboard/project/:id/:page?',
    element: <Page Layout={VerticalDefault} Component={ProjectDetail} />,
  },
  {
    path: '/dashboard/document/:id',
    element: <Page Layout={VerticalDefault} Component={DocumentDetail} />,
  },
  {
    path: '/account/profile',
    element: <Page Layout={VerticalDefault} Component={Profile} />,
  },
  {
    path: '/account/billing',
    element: <Page Layout={VerticalDefault} Component={PricingPlan} />,
  },
];

/**
 routes only accessible to anonymous users
 **/
const routesForNotAuthenticatedOnly: RouteObject[] = [
  {
    path: '',
    element: <Page Component={Landing} Layout={SoloPage} componentProps={{ disableSmLogin: true }} />,
  },
  {
    path: '/user/login',
    element: <Page Component={Login} Layout={SoloPage} componentProps={{ disableSmLogin: true }} />,
  },
  {
    path: '/user/login/:tempToken',
    element: <Page Component={Login} Layout={SoloPage} componentProps={{ disableSmLogin: true }} />,
  },
  // {
  //   path: '/user/signup',
  //   element: <Page Component={Login} layout={LAYOUT_NAMES.SOLO_PAGE} componentProps={{ disableSmLogin: true }} type="signup" />
  // },
  {
    path: '/user/reset-password',
    element: <Page Component={ResetPassword} Layout={SoloPage} />,
  },
  {
    path: '/user/set-password/:tempToken',
    element: <Page Component={SetPassword} Layout={SoloPage} />,
  },
];

// NOTE: routes _should_ be nestable via this config approach
// -- when https://reactrouter.com/en/main/route/route#children is populated, refactor this

const routes = [...routesForPublic, ...routesForAuthenticatedOnly, ...routesForNotAuthenticatedOnly];

export { routes as default, routesForPublic, routesForNotAuthenticatedOnly, routesForAuthenticatedOnly };
