import { ChangeEvent, useState } from 'react';
import {
  Badge,
  Button,
  Dialog,
  DialogTitle,
  DialogFooter,
  DialogContent,
  DialogBody,
  DialogHeader,
  DialogTrigger,
  Avatar,
  AvatarImage,
  Card,
  CardContent,
  CardFooter,
  ScrollArea,
} from '@shadcn/ui';
import { InputWrapper } from '@shadcn/custom/InputWrapper';
import { MailIcon, UserPlusIcon, Building2Icon } from 'lucide-react';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import userServices from 'app/services/user-services';

const defaultAvatarUrl =
  'https://static.vecteezy.com/system/resources/thumbnails/001/840/618/small/picture-profile-icon-male-icon-human-or-people-sign-and-symbol-free-vector.jpg';

export const Profile = () => {
  const { authUser } = useJumboAuth();
  // @ts-ignore
  const user = authUser?.attributes;
  const organizationName = authUser?.relationships?.organization?.data?.name;
  const avatar = user?.avatar;

  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [inviteSent, setInviteSent] = useState(false);
  const [inviteError, setInviteError] = useState('');

  const handleClickOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setEmail('');
    setEmailError(false);
    setInviteSent(false);
    setInviteError('');
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setEmailError(false);
  };

  const handleSendRequest = async () => {
    if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError(true);
    } else {
      try {
        await userServices.invite({ email });
        setInviteSent(true);
      } catch (error) {
        setInviteError('Failed to send invite. Please try again.');
      }
    }
  };

  return (
    <ScrollArea className="grow">
      <Card className="mx-auto mt-8 max-w-screen-sm rounded-xl border-border">
        <CardContent className="pt-6">
          <div className="mb-6 flex flex-row items-center gap-4">
            <Avatar className="h-20 w-20">
              <AvatarImage src={avatar || defaultAvatarUrl} />
            </Avatar>
            <div className="flex flex-col gap-2">
              <h5 className="text-lg font-bold">{user?.name || 'User Name'}</h5>
              <Badge variant="secondary" className="gap-2 text-muted-foreground">
                <Building2Icon className="my-0.5 h-4 w-4" /> {organizationName}
              </Badge>
            </div>
          </div>

          <p className="inline-flex items-center gap-2">
            <MailIcon className="stroke-muted-foreground" />
            {user?.email}
          </p>
        </CardContent>
        <CardFooter>
          <Dialog open={open} onOpenChange={(newOpen) => (newOpen ? handleClickOpen() : handleClose())}>
            <DialogTrigger asChild>
              <Button onClick={handleClickOpen} className="w-full">
                <UserPlusIcon /> Invite User
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Invite to {organizationName}</DialogTitle>
              </DialogHeader>
              <DialogBody>
                <p>Enter the email address of the user you'd like to invite to join the organization.</p>
                <InputWrapper
                  autoFocus
                  id="email"
                  label="Email Address"
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  error={emailError}
                  helperText={emailError ? 'Please enter a valid email' : undefined}
                />
                {inviteSent ? (
                  <p className="bg-positive text-positive-foreground">Invitation sent successfully!</p>
                ) : null}
                {inviteError ? <p className="bg-destructive text-destructive-foreground">{inviteError}</p> : null}
              </DialogBody>
              <DialogFooter>
                <Button onClick={handleClose} variant="secondary">
                  {inviteSent ? 'Close' : 'Cancel'}
                </Button>
                <Button onClick={handleSendRequest} disabled={!email || inviteSent}>
                  Send Invitation
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </CardFooter>
      </Card>
    </ScrollArea>
  );
};
